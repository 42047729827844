<template>
  <div class="level p-1">
    <div class="level-left">
      <router-link :to="`/view/${firm.id}`" class="is-size-4 has-text-primary-dark firm-name">{{ firm.name }}</router-link>
    </div>
    <div class="level-right">
      <div class="tags">
        <b-tag type="is-gold" v-if="firm.premium">Platinum Sponsor</b-tag>
        <b-tag type="is-green" v-if="firm.accredited">Accredited</b-tag>
        <b-tag v-if="firm.underbar">Underbars Accepted</b-tag>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirmList",
  props: ['firm']
}
</script>

<style scoped>
.firm-name {
  font-family: "Alatsi", sans-serif;
}

.level {
  border-bottom: solid 1px #969696;
}

@media only screen and (max-width: 768px) {
  .level-right {
    margin-top: 0px !important;
  }
}
</style>