<template>
  <div class="main">
    <b-skeleton width="20%"></b-skeleton>
    <b-skeleton width="40%"></b-skeleton>
    <b-skeleton width="80%"></b-skeleton>
    <b-skeleton width="100%"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>
.main {
  width: 100%;
}
</style>